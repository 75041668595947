import { Component } from '@angular/core';
import { CrmAppComponent } from 'common-module/core';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [CrmAppComponent],
})
export class AppComponent {}
