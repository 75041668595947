import { CrmEndpointModuleConfig } from 'common-module/endpoint';

import { environment } from '../../environments/environment';

export const endpointConfig: CrmEndpointModuleConfig = {
  endpoints: [
    {
      name: 'api',
      baseUrl: environment.api,
      withCredentials: true,
    },
  ],
};
