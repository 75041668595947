import {
  CrmLayoutCollapseComponent,
  CrmLayoutLogoComponent,
  CrmLayoutMenuComponent,
  crmLayoutSidebarOnly,
  CrmLayoutV2Config,
} from 'common-module/layout-v2';

import { LogoutComponent } from '../layout/logout.component';
import { MenuProvider } from '../layout/menu.provider';

export const layoutConfig: CrmLayoutV2Config = {
  ...crmLayoutSidebarOnly,
  siderLeft: {
    ...crmLayoutSidebarOnly.siderLeft,
    fragments: [
      { key: 'logo', content: CrmLayoutLogoComponent },
      { key: 'menu', content: CrmLayoutMenuComponent, provider: MenuProvider },
      { key: 'collapse', content: CrmLayoutCollapseComponent },
      { key: 'logout', content: LogoutComponent },
    ],
  },
  menu: {
    prefix: 'app',
    items: [
      {
        key: 'home.title',
        icon: 'home',
        routeKey: 'home',
        role: ['SalesRepresentative', 'Admin', 'SuperAdmin'],
      },
      {
        key: 'supplements.title',
        icon: 'build',
        routeKey: 'supplements',
        role: ['SalesRepresentative', 'Admin', 'SuperAdmin'],
      },
      {
        key: 'packagings.title',
        icon: 'gift',
        routeKey: 'packagings',
        role: ['SalesRepresentative', 'Admin', 'SuperAdmin'],
      },
      {
        key: 'products.title',
        icon: 'shopping-cart',
        routeKey: 'products',
        role: ['SalesRepresentative', 'Admin', 'SuperAdmin'],
      },
      {
        key: 'customers.title',
        icon: 'user',
        routeKey: 'customers',
        role: ['SalesRepresentative', 'Admin', 'SuperAdmin'],
      },
      {
        key: 'users.title',
        icon: 'team',
        routeKey: 'users',
        role: ['Admin', 'SuperAdmin'],
      },
      {
        key: 'roles.title',
        icon: 'key',
        routeKey: 'roles',
        role: ['SuperAdmin'],
      },
      {
        key: 'settings.title',
        icon: 'setting',
        routeKey: 'settings',
        role: ['Admin', 'SuperAdmin'],
      },
    ],
  },
  content: {
    footer: {
      fragments: [
        // TODO: version component
      ],
    },
  },
};
