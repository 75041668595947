import { inject, Injectable } from '@angular/core';
import {
  CrmLayoutMenuProvider,
  CrmLayoutSiderControl,
} from 'common-module/layout-v2';
import { map } from 'rxjs';

import { AuthService } from '~/api/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class MenuProvider extends CrmLayoutMenuProvider {
  private user = inject(AuthService);

  override items$(_options: { siderControl?: CrmLayoutSiderControl }) {
    return super.items$(_options).pipe(
      map((items) =>
        items.filter(({ role }) => {
          if (!role || role.length === 0) {
            return false;
          }

          if (typeof role === 'string') {
            return this.user.userRoles().some((ur) => ur === role);
          }

          return role.some((r) => this.user.userRoles().some((ur) => ur === r));
        }),
      ),
    );
  }
}
