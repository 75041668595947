import sk from '@angular/common/locales/sk';
import { CrmTranslateConfig } from 'common-module/translate';
import dfnsSk from 'date-fns/locale/sk';
import { sk_SK } from 'ng-zorro-antd/i18n';

export const translateConfig: CrmTranslateConfig = {
  locale: {
    defaultLocale: 'sk',
    locales: {
      sk: {
        ngLocale: sk,
        nzLocale: sk_SK,
        dateFnsLocale: dfnsSk,
      },
    },
  },
};
