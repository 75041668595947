import { CrmAppConfiguration } from 'common-module/core/types';
import { CrmFilterConfig } from 'common-module/filter';

import { environment } from '../../environments/environment';

export const crmConfig: CrmAppConfiguration = {
  environment,
  modules: {
    filter: { actions: { clear: { enabled: false } } } as CrmFilterConfig,
  },
};
