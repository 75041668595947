import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  inject,
  OnInit,
  signal,
  Signal,
} from '@angular/core';
import { crmKillEvent } from 'common-module/core';
import { CrmLayoutV2FragmentDirective } from 'common-module/layout-v2';
import { CrmModalService } from 'common-module/modal';
import { CrmTranslateService } from 'common-module/translate';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';
import { NzIconDirective } from 'ng-zorro-antd/icon';

import { AuthService } from '~/api/auth/auth.service';

import { LogoutProvider } from './logout.provider';

@Component({
  standalone: true,
  selector: 'app-logout',
  template: `
    @if (!collapsed()) {
      <div class="layout-logout__user">
        <nz-avatar nzIcon="user"></nz-avatar>
        <span>{{ user.name() }}</span>
      </div>
    }

    <div class="layout-logout__sign-out">
      <span nz-icon nzType="poweroff" nzTheme="outline"></span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzIconDirective, NzAvatarComponent],
  providers: [LogoutProvider],
})
export class LogoutComponent
  extends CrmLayoutV2FragmentDirective<LogoutProvider>
  implements OnInit
{
  @HostBinding('class')
  protected readonly hostClass = 'layout-logout';

  protected collapsed!: Signal<boolean>;

  protected user = inject(AuthService);
  private modal = inject(CrmModalService);
  private ts = inject(CrmTranslateService);

  @HostBinding('class.layout-logout--collapsed')
  get collapsedClass() {
    return this.collapsed();
  }

  ngOnInit() {
    this.initProvider(LogoutProvider);
  }

  @HostListener('click', ['$event'])
  protected handleClick(event: MouseEvent) {
    crmKillEvent(event);

    this.modal.confirm({
      nzTitle: this.ts.get('logout.modal.title'),
      nzOkText: this.ts.get('logout.modal.submit'),
      nzOkDanger: true,
      nzOnOk: () => this.user.logout().subscribe(),
    });
  }

  protected override initComponent() {
    this.collapsed = this.siderControl?.collapsed ?? signal(false).asReadonly();
  }
}
